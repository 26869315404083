

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.intro-text {
  font-size:16px;
  line-height: 16px;
  justify-content: center;
  margin-bottom: 15px;
}

.container {
  max-width: 850px !important;
  /* background-color: #fbf9fa; */
  
  padding: 10px;
  /* box-shadow: 5px 10px #f1f1f1; */
}

.bg-dark {
  background-color: #e6e6e6 !important;
}

h1 {
  
  font-size: 35px;
}

.select_box {
  border-radius: 999px;
  font-size:11px;
}


.createBtn {
  cursor: pointer;
}

.form_column {
  border-right:1px solid #e0e0e0;
}



.btn-active {background-image: linear-gradient(to right, #73c9bc 0%, #6cc073  51%, #73c9bc  100%)}
.btn-active {
   margin: 5px;
  
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   font-size:11px;
  
 }

 .btn-active:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


 
 .btn-normal {background-image: linear-gradient(to right, #616161 0%, #9bc5c3  51%, #616161  100%)}
 .btn-normal {
    margin: 5px;
    /* padding: 10px 15px; */
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    font-size: 11px;
   
  }

  .btn-normal:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .color-picker-palette {
    position: absolute;
    z-index:100;
  }

  .color-picker-cover {
    position: fixed;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    
  }

  .dropdown_wrapper {
    background:#fff;
    border-radius:30px;
    border:1px solid #ccc;
    margin-left:0px;
    margin-right:0px;

  }

  .dimension_dropdown {
    border:none !important;
    font-size: 11px;
  }

  .color-buttons {
    border-radius:20px;
    height:30px;
    width: 30px !important;
    margin-top:1px;
    cursor: pointer;
  }


  h3 {
    color: #000 !important;
  }
  
 