@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container_bg {
  background-color: #fff !important;
}

.container_bg2 {
  background-color: #000 !important;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 20% !important;
  right: 35% !important;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}


.displayMsg {
  font-size: 10px;
  color: #333 ;
}

h4 {
  font-size: 16px !important;
}

a {
  color: #000 !important;
}